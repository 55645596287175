import React from 'react'
import {FaGithub, FaLinkedin} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {AiOutlineInstagram} from 'react-icons/ai'

const SocialLinks = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                Linkedin <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/feed/',
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                Github <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/LaithAhmed9',
        },
        {
            id: 3,
            child: (
                <>
                Mail <HiOutlineMail size={30} />
                </>
            ),
            href: 'https://eng.laith.ahmad1993@gmail.com',
        },
        {
            id: 4,
            child: (
                <>
                 Instagram <AiOutlineInstagram size={30} />
                </>
            ),
            href: 'https://instagram.com/l1a91?igshid=YmMyMTA2M2Y=',
        },
        {
            id: 5,
            child: (
                <>
                 Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: '/cvLaith Ahmed.pdf',
            style: 'rounded-br-md',
            download: true
        },
    ]
  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {links.map(({id, child, href, style, download}) => (
                
            <li key={id} className={`flex justify-center items-center w-40 h-14 px-4 ml-[-100px]
             hover:ml-[-10px] hover:rounded-md duration-300 bg-gray-500` + " " + style}>
                <a
                    href={href}
                    className='flex justify-between items-center w-full text-white'
                    download={download}
                    target="-blank"    
                    rel='noreferrer'
                >
                    {child}
                </a>
            </li>
            ))}
        </ul>
    </div>
  )
}

export default SocialLinks  